import React, { useEffect } from 'react';
import { Card, Col, Form, Input, Row, Space, Switch } from 'antd';
import FeatureItem from './feature-item';
import featureStore from 'common/stores/features/feature.store';
import { observer } from 'mobx-react-lite';
import Feature from 'common/components/feature/feature';
import { FeatureName } from 'common/enums/feature-name.enum';
import { notification } from 'common/utils/notification';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ISystemConfigurationUpdate } from 'common/services/configuration-service/configuration.service.types';
import configurationService from 'common/services/configuration-service/configuration.service';
import configurationStore from 'common/stores/configuration/configuration.store';
import styles from './features.module.scss';

const Features = observer(() => {
  const initData = featureStore.features;
  const configInitData = configurationStore.configuration?.citations;

  const [form] = Form.useForm();

  let timeoutId: any;

  const handleFormValuesChange = () => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      form.submit();
    }, 1000);
  };

  const onFinish = async (values: any) => {
    const model: ISystemConfigurationUpdate = {
      citations: {
        firstCitationFineAmount: null,
        firstCitationSpeedToleranceInPercentages: null,
        secondCitationFineAmount: null,
        thirdCitationFineAmount: null,
        citationPaymentCommissionInPercentages: Number(values.citationPaymentCommissionInPercentages),
        isNotificationsEnabled: values.isNotificationsEnabled,
      },
      serviceHours: null,
      default: null,
    };
    const result = await configurationService.updateSystemConfiguration(model);
    configurationStore.setConfiguration(result);
    notification.success({
      message: 'Configurations successfully updated',
      duration: 5,
    });
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue(configInitData);
    }
  }, [form, configInitData]);

  return (
    <Card className="eiq-card eiq-card-border eiq-configuration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">Feature management</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <FeatureItem feature={featureStore.getByName(FeatureName.SmsNotifications)!} />
          <div className={styles.container}>
            <div className={styles.title}>Napco Security</div>
            <FeatureItem feature={featureStore.getByName(FeatureName.CA3000)!} />
            <FeatureItem feature={featureStore.getByName(FeatureName.CA3000CardClasses)!} disabled={!featureStore.isCardAccessEnabled} />
            <FeatureItem feature={featureStore.getByName(FeatureName.CA3000Expirations)!} disabled={!featureStore.isCardAccessEnabled} />
            <FeatureItem feature={featureStore.getByName(FeatureName.CA3KLogsImport)!} disabled={!featureStore.isCardAccessEnabled} />
            <FeatureItem feature={featureStore.getByName(FeatureName.CA4K)!} />
          </div>
          <FeatureItem feature={featureStore.getByName(FeatureName.Reports)!} />
          <FeatureItem feature={featureStore.getByName(FeatureName.Connect)!} />
          <div className={styles.container}>
            <FeatureItem feature={featureStore.getByName(FeatureName.ConnectEditPin)!} />
            <FeatureItem feature={featureStore.getByName(FeatureName.FastAccess)!} />
          </div>
          <FeatureItem feature={featureStore.getByName(FeatureName.NorthStar)!} />
          <FeatureItem feature={featureStore.getByName(FeatureName.CreateResidentAnonymously)!} />
          <div className={styles.container}>
            <div className={styles.title}>Traffic Logix</div>
            <FeatureItem feature={featureStore.getByName(FeatureName.TrafficLogix)!} />
            <Feature name={FeatureName.TrafficLogix}>
              <FormWrapper
                form={form}
                onFinish={onFinish}
                onValuesChange={handleFormValuesChange}
                initialValues={configInitData}
                layout="vertical"
                className={styles.EIQIntelliTicketConfiguration}>
                <Space className="full-width" size={13}>
                  <h1 className="h1">EIQ-IntelliTicket configuration</h1>
                </Space>
                <Space className="full-width" size={13}>
                  <Form.Item name="citationPaymentCommissionInPercentages" label="EIQ payment commission (%)">
                    <Input placeholder="Enter commission amount" type="number" min={0} step={0.1} />
                  </Form.Item>
                </Space>
                <Space className="full-width" size={13}>
                  <div className={`${styles.switch} ${styles.trafficLogixNotification}`}>
                    <span>Notifications</span>
                    <Form.Item name="isNotificationsEnabled" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </div>
                </Space>
              </FormWrapper>
            </Feature>
            <FeatureItem feature={featureStore.getByName(FeatureName.EIQViolation)!} />
          </div>
        </Col>
      </Row>
    </Card>
  );
});

export default Features;
