import React, { useEffect, useRef } from 'react';
import './App.css';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../common/components/base/private-route/private-route';
import { EiqManageLayout } from '../features/eiq-manage/layout/eiq-manage-layout';
import { Residents } from '../features/eiq-manage/pages/eiq-manage-residents/residents';
import { PermissionEnum } from '../common/enums/permission.enum';
import { LoginLayout } from '../features/login/layout/login-layout';
import { Login } from '../features/login/pages/login/login';
import { ForgotPassword } from '../features/login/pages/forgot-password/forgot-password';
import { SetNewPassword } from '../features/login/pages/set-new-password/set-new-password';
import { PasswordReset } from '../features/login/pages/password-reset/password-reset';
import userAuthStore from '../common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from '../common/enums/user-type.enum';
import { Addresses } from 'features/eiq-manage/pages/eiq-manage-addresses/addresses';
import { Streets } from 'features/eiq-manage/pages/eiq-manage-addresses/tabs-content-pages/streets/streets';
import { Communities } from 'features/eiq-manage/pages/eiq-manage-addresses/tabs-content-pages/communities/communities';
import { EiqSecureLayout } from '../features/eiq-secure/layout/eiq-secure-layout';
import { EiqSecure } from '../features/eiq-secure/eiq-secure';
import { UserAccounts } from 'features/eiq-manage/pages/eiq-manage-accounts/tabs-content-pages/user-accounts';
import { Accounts } from 'features/eiq-manage/pages/eiq-manage-accounts/accounts';
import { AccountTypes } from 'features/eiq-manage/enums/account-type.enum';
import { Traffic } from '../features/eiq-secure/pages/traffic/traffic';
import { Configurations } from 'features/eiq-manage/pages/eiq-manage-configurations/configurations';
import { DefaultConfiguration } from 'features/eiq-manage/pages/eiq-manage-configurations/tabs-content-pages/default/default-configuration';
import { ServiceHoursConfiguration } from 'features/eiq-manage/pages/eiq-manage-configurations/tabs-content-pages/service-hours/service-hours-configuration';
import { KeysManagement } from '../features/eiq-manage/pages/eiq-manage-addresses/tabs-content-pages/keys-management/keys-management';
import { Keys } from '../features/eiq-secure/pages/keys/keys';
import { AdminVehicles } from 'features/eiq-manage/pages/eiq-manage-vehicles/vehicles';
import Features from 'features/eiq-manage/pages/eiq-manage-features/features';
import featureStore from 'common/stores/features/feature.store';
import AddressesManagement from 'features/eiq-manage/pages/eiq-manage-addresses/tabs-content-pages/addresses/addresses-management';
import { Citations } from 'features/eiq-manage/pages/eiq-manage-citations/citations';
import CitationConfigurations from 'features/eiq-manage/pages/eiq-manage-configurations/tabs-content-pages/citations/citations-configurations';
import { AccountInfo } from 'features/eiq-manage/pages/eiq-manage-accounts/tabs-content-pages/account-info';
import configurationStore from 'common/stores/configuration/configuration.store';
import zenDeskService from 'common/services/zendesk/zendesk.service';
import { loadZendeskWidget } from 'common/helpers/zendesk.helper';
import { Reports } from 'features/eiq-manage/pages/eiq-manage-reports/reports';
import { VehicleApprovals } from 'features/eiq-manage/pages/eiq-manage-vehicles/tab-content-pages/vehicle-approvals/vehicle-approvals';
import { AllVehicles } from 'features/eiq-manage/pages/eiq-manage-vehicles/tab-content-pages/all-vehicles/all-vehicles';
import { AnonymousRegistration } from 'features/eiq-manage/pages/eiq-manage-anonymous-registration/anonymous-registration';
import { AllDevices } from 'features/eiq-manage/pages/eiq-manage-vehicles/tab-content-pages/all-devices/all-devices';

const App = ({ props }: any) => {
  const defaultConfiguration = configurationStore.configuration?.default;

  useEffect(() => {
    if (userAuthStore.isLogin) {
      featureStore.requestFeatures();
    }
  }, []);

  useEffect(() => {
    if (!defaultConfiguration) {
      return;
    }

    if (userAuthStore.isLogin) {
      const zendeskApiKey = process.env.REACT_APP_ZENDESK_WIDGET_KEY;

      if (!zendeskApiKey) {
        console.log('ZENDESK is disabled');
        return;
      }

      loadZendeskWidget(zendeskApiKey, () => zenDeskService.login(userAuthStore.userType!, defaultConfiguration.communityName!));
    }
  }, [defaultConfiguration, userAuthStore.isLogin]);

  useEffect(() => {
    const communityName = configurationStore.configuration?.default.communityName;
    const userType = userAuthStore.userType;

    if (!communityName) {
      document.title = 'EntranceIQ';
      return;
    }

    if (userType === 'admin') {
      document.title = `EIQ manage: ${communityName}`;
    } else if (userType === 'security_officer') {
      document.title = `EIQ secure: ${communityName}`;
    } else {
      document.title = 'EntranceIQ';
    }
  }, [configurationStore.configuration, userAuthStore.isLogin, userAuthStore.userType]);

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/registration" element={<AnonymousRegistration />} />
          {!userAuthStore.isLogin && <Route path="/*" element={<Navigate to="/login" replace={true} />} />}

          {/* Redirect after login START*/}
          {userAuthStore.isLogin &&
            (userAuthStore.userType === UserTypeEnum.Admin || userAuthStore.userType === UserTypeEnum.SuperAdmin) && (
              <Route path="/" element={<Navigate to={userAuthStore.prevPath || '/eiq-manage'} replace={true} />} />
            )}
          {/* Need to update back-end with SecurityOfficer user type in access token */}
          {userAuthStore.isLogin &&
            userAuthStore.userType !== UserTypeEnum.Admin &&
            userAuthStore.userType !== UserTypeEnum.SuperAdmin &&
            userAuthStore.userType !== UserTypeEnum.WebGuest && (
              <Route path="/" element={<Navigate to={userAuthStore.prevPath || '/eiq-secure'} replace={true} />} />
            )}
          {/* END Redirect after login */}

          <Route element={<LoginLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/set-new-password" element={<SetNewPassword />} />
            <Route path="/password-reset" element={<PasswordReset />} />
          </Route>

          {userAuthStore.userType === UserTypeEnum.Admin ? (
            <Route path="eiq-manage" element={<PrivateRoute userTypes={[UserTypeEnum.Admin]} element={<EiqManageLayout />} />}>
              <Route index element={<Navigate to="/eiq-manage/residents" replace={true} />} />
              {/*Example of inner permissions*/}
              {/*<Route path="residents" element={<PrivateRoute permissions={[PermissionEnum.ResidentView]} element={<Residents />} />} />*/}
              <Route path="residents" element={<Residents />} />
              <Route path="non-resident-users" element={<div>non-residents-users</div>} />
              {featureStore.isReportsEnabled && <Route path="reports" element={<Reports />} />}
              <Route path="addresses" element={<Addresses />}>
                <Route index element={<Navigate to="/eiq-manage/addresses/addresses-management" replace={true} />} />
                <Route path="addresses-management" element={<AddressesManagement />} />
                <Route path="streets" element={<Streets />} />
                <Route path="communities" element={<Communities />} />
                <Route path="keys-management" element={<KeysManagement />} />
              </Route>
              <Route path="personnel-access" element={<div>Personnel access</div>} />
              <Route path="security-and-auditing" element={<div>Security and auditing</div>} />
              <Route path="traffic" element={<Traffic />} />
              <Route path="system-configuration" element={<Configurations />}>
                <Route index element={<Navigate to="/eiq-manage/system-configuration/default" replace={true} />} />
                <Route path="general" element={<div>General</div>} />
                <Route path="default" element={<DefaultConfiguration />} />
                <Route path="service-hours" element={<ServiceHoursConfiguration />} />
                {featureStore.isTrafficLogixEnabled && <Route path="citations" element={<CitationConfigurations />} />}
              </Route>
              <Route path="account-management" element={<Accounts />}>
                <Route index element={<Navigate to="/eiq-manage/account-management/account-info" replace={true} />} />
                <Route path="account-info" element={<AccountInfo />} />
                <Route path="security-officers" element={<UserAccounts accountType={AccountTypes.SecurityOfficer} />} />
              </Route>
              <Route path="vehicles" element={<AdminVehicles />}>
                <Route index element={<Navigate to="/eiq-manage/vehicles/approvals" replace={true} />} />
                <Route path="all" element={<AllVehicles />} />
                <Route path="approvals" element={<VehicleApprovals />} />
                <Route path="devices" element={<AllDevices />} />
              </Route>
              {featureStore.isTrafficLogixEnabled && <Route path="citations" element={<Citations />} />}
            </Route>
          ) : (
            <Route path="eiq-manage" element={<PrivateRoute userTypes={[UserTypeEnum.SuperAdmin]} element={<EiqManageLayout />} />}>
              <Route index element={<Navigate to="/eiq-manage/account-management" replace={true} />} />
              <Route path="account-management" element={<Accounts />}>
                <Route index element={<Navigate to="/eiq-manage/account-management/admins" replace={true} />} />
                <Route path="admins" element={<UserAccounts accountType={AccountTypes.Admin} />} />
                <Route path="security-officers" element={<UserAccounts accountType={AccountTypes.SecurityOfficer} />} />
              </Route>
              <Route path="feature-management" element={<Features />} />
            </Route>
          )}

          <Route
            path="eiq-secure"
            element={
              <PrivateRoute
                permissions={[PermissionEnum.readResident, PermissionEnum.updateResident, PermissionEnum.writeResident]}
                element={<EiqSecureLayout />}
              />
            }>
            <Route index element={<EiqSecure />} />
            <Route path="traffic" element={<PrivateRoute permissions={[PermissionEnum.readTrafficLog]} element={<Traffic />} />} />
            <Route path="keys" element={<PrivateRoute permissions={[PermissionEnum.readTrafficLog]} element={<Keys />} />} />
            <Route path="function-guests-list" element={<div>Function guests list</div>} />
            <Route path="reports" element={<div>Reports</div>} />
            <Route path="global-contractor-list" element={<div>Global contractor list</div>} />
            <Route path="system-configuration" element={<div>Settings</div>} />
          </Route>

          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </Router>
    </>
  );
};

export default observer(App);
