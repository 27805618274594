import { useLocation, Outlet } from 'react-router-dom';
import React from 'react';
import { Button, Layout, Menu, MenuProps, Space } from 'antd';
import './eiq-manage-layout.scss';
import { SH } from 'common/components';
import { ReactComponent as CloudDownload } from 'assets/icons/cloud-download.svg';
import { ReactComponent as Exit } from 'assets/icons/exit.svg';
import { ReactComponent as Help } from 'assets/icons/help.svg';
import LogoIcon from 'assets/icons/eiq-logo.png';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { UserTypeEnum } from 'common/enums/user-type.enum';
import { userTypeMap } from 'common/constans/user-type.constants';
import featureStore from 'common/stores/features/feature.store';
import { observer } from 'mobx-react-lite';

const { Header, Footer, Content } = Layout;

const items: MenuProps['items'] = [
  {
    label: 'lable',
    key: '/eiq-manage/residents',
  },
];

export const EiqManageLayout = observer((props: any) => {
  const location = useLocation();

  const currentMenuKey = (key: any) => {
    if (location.pathname.search(key) >= 0) {
      return location.pathname;
    } else {
      return key;
    }
  };

  let items: MenuProps['items'] = [
    {
      label: <SH.HotkeyLink to={'/eiq-manage/residents'} label={'Residents'} />,
      key: '/eiq-manage/residents',
    },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-manage/non-resident-users'} hotkey={'alt+N'} keyLabel={'N'} label={'Non-resident users'} />,
    //   key: '/eiq-manage/non-resident-users',
    // },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-manage/reports'} hotkey={'alt+R'} keyLabel={'R'} label={'Reports'} />,
    //   key: '/eiq-manage/reports',
    // },
    {
      label: <SH.HotkeyLink to={'/eiq-manage/addresses'} label={'Addresses'} />,
      key: currentMenuKey('/eiq-manage/addresses'),
    },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-manage/personnel-access'} hotkey={'alt+E'} keyLabel={'E'} label={'Personnel access'} />,
    //   key: '/eiq-manage/personnel-access',
    // },
    // {
    //   label: <SH.HotkeyLink to={'/eiq-manage/security-and-auditing'} hotkey={'alt+U'} keyLabel={'U'} label={'Security and auditing'} />,
    //   key: '/eiq-manage/security-and-auditing',
    // },
    {
      label: <SH.HotkeyLink to="/eiq-manage/account-management" label={'Account management'} />,
      key: currentMenuKey('/eiq-manage/account-management'),
    },
    {
      label: <SH.HotkeyLink to="/eiq-manage/vehicles" label={'Vehicles/Devices'} />,
      key: '/eiq-manage/vehicles',
    },
  ];

  if (featureStore.isTrafficLogixEnabled) {
    items.push({
      label: <SH.HotkeyLink to={'/eiq-manage/citations'} label="Citations" />,
      key: currentMenuKey('/eiq-manage/citation'),
    });
  }

  if (featureStore.isReportsEnabled) {
    items.push({
      label: <SH.HotkeyLink to={'/eiq-manage/reports'} label="Reports" />,
      key: currentMenuKey('/eiq-manage/reports'),
    });
  }

  items.push({
    label: <SH.HotkeyLink to={'/eiq-manage/traffic'} label={'Traffic'} />,
    key: '/eiq-manage/traffic',
  });

  items.push({
    label: <SH.HotkeyLink to={'/eiq-manage/system-configuration'} label={'Settings'} />,
    key: currentMenuKey('/eiq-manage/system-configuration'),
  });

  if (userAuthStore.userType === UserTypeEnum.SuperAdmin) {
    items = [
      {
        label: <SH.HotkeyLink to="/eiq-manage/account-management" label={'Account management'} />,
        key: currentMenuKey('/eiq-manage/account-management'),
      },
      {
        label: <SH.HotkeyLink to="/eiq-manage/feature-management" label={'Feature management'} />,
        key: '/eiq-manage/feature-management',
      },
    ];
  }

  return (
    <div className="eiq-manage">
      <Layout>
        <Header>
          <img className="logo" src={LogoIcon} alt="logo" />

          <Menu
            defaultSelectedKeys={[location.pathname]}
            className="cc-menu-item"
            items={items}
            mode="horizontal"
            selectedKeys={[location.pathname]}
          />

          <span className="user-name">{userAuthStore.userType && userTypeMap[userAuthStore.userType]}</span>
          <Button type="ghost" icon={<CloudDownload />} />
          <Button type="link" target="_blank" href="https://my.entranceiq.support/" icon={<Help />} />
          <Button
            type="ghost"
            icon={<Exit />}
            onClick={() => {
              userAuthStore.logOut();
            }}
          />
        </Header>
        <Content className="content-style">
          <Outlet />
        </Content>
      </Layout>
    </div>
  );
});
