export enum FeatureName {
  FastAccess = 'FastAccess',
  SmsNotifications = 'SmsNotifications',
  CA4K = 'CA4K',
  CA3000 = 'CA3000',
  TrafficLogix = 'TrafficLogix',
  Reports = 'Reports',
  Connect = 'Connect',
  CA3000CardClasses = 'CA3000CardClasses',
  CA3000Expirations = 'CA3000Expirations',
  NorthStar = 'NorthStar',
  ConnectEditPin = 'ConnectEditPin',
  CA3KLogsImport = 'CA3KLogsImport',
  CreateResidentAnonymously = 'CreateResidentAnonymously',
  EIQViolation = 'EIQViolation',
}

export const featureNameMap: Record<FeatureName, string> = {
  [FeatureName.FastAccess]: 'EIQ-Passport',
  [FeatureName.SmsNotifications]: 'SMS Notifications',
  [FeatureName.CA4K]: 'CA4K',
  [FeatureName.CA3000]: 'CA3000',
  [FeatureName.TrafficLogix]: 'EIQ-IntelliTicket',
  [FeatureName.Reports]: 'EIQ-Insight',
  [FeatureName.Connect]: 'EIQ-Connect',
  [FeatureName.CA3000CardClasses]: 'CA3000 Card Classes',
  [FeatureName.CA3000Expirations]: 'CA3000 Expirations',
  [FeatureName.NorthStar]: 'EIQ-NorthSync',
  [FeatureName.ConnectEditPin]: 'Edit Pin',
  [FeatureName.CA3KLogsImport]: 'CA3000 Event Logs',
  [FeatureName.CreateResidentAnonymously]: 'Anonymous Resident Creation',
  [FeatureName.EIQViolation]: 'EIQ-Violation',
};
